import {css} from '@emotion/react'
import {unitConverter, colors, fonts, breakpoints} from "@utils/base";

export const menuCss = css`
  padding: 0;
  border-bottom: ${unitConverter('1px')} solid ${colors.darkGrayHover};
  color: white;

  background-color: ${colors.darkGray};

  .quantity {
    display: inline-block;
    margin-left: ${unitConverter('10px')};
    background-color: ${colors.lightGray};
    border-radius: ${unitConverter('20px')};
    padding: 5px 10px;
    color: ${colors.darkGray};
    font-size: .6em;
    position: relative;
    top: -3px;

    &.empty {
      background-color: ${colors.darkGray};
      display: none;
    }
  }

  .hamburger {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    display: none;
    padding: 10px 10px 0;
    margin-bottom: 5px;
    ${breakpoints.tabletPortrait} {
      display: block;
    }
  }

  ul.mobile-wrap {
    list-style: none outside;
    padding: 0 20px;

    ${breakpoints.tabletPortrait} {
      padding: 0;
    }

    > li {
      display: flex;
      align-items: center;

      .nav {
        flex-grow: 1;
      }

      ${breakpoints.tabletPortrait} {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  ul.nav {
    list-style: none outside;
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: flex-end;
     transition: all .4s ease-in-out;
     font-family: ${fonts.tektonPro};
     align-items: center;

     ${breakpoints.tabletPortrait} {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        justify-content: center;
     }

    &.closed {
      display: none;
    }

    li {
      margin-left: ${unitConverter('40px')};
      padding: ${unitConverter('20px 15px 10px')};
      position: relative;
      color: ${colors.lightGray};
      cursor: default;
      font-size: 1.2em;

      ${breakpoints.tabletPortrait} {
        margin-left: 0;
        border-bottom: 1px solid ${colors.lightGray};
        width: 100%;
        padding: 20px;
      }

      &:first-of-type {
        ${breakpoints.tabletPortrait} {
          border-top: 1px solid ${colors.lightGray};
        }
      }

      &:last-of-type {
        padding-right: 0;
      }

      h3 {
        font-weight: 300;
        font-size: inherit;
      }

      a {
        color: ${colors.lightGray};
        text-decoration: none;
        display: flex;
        align-items: center;

        &:hover {
          color: ${colors.lightGrayHover};

          svg {
            fill: ${colors.lightGrayHover};
            opacity: 1;
          }
        }
      }

      > ul {
        height: 0;
        display: none;
        opacity: 0;
        position: absolute;
        margin: 0;
        display: block;
        left: 0;
        z-index: 10;
        font-family: ${fonts.signo};
        overflow: hidden;
        width: 200%;

        li {
          padding: 20px 20px 0;
          background-color: ${colors.darkGray};
          padding-left: ${unitConverter('20px')};
          margin-left: 0;
          font-size: .8em;

          &:first-of-type {
            padding-bottom: 0;
            padding-top: 20px;
          }

          &:last-of-type {
            padding-bottom: 20px;
          }
        }
      }

      &:hover {
        color: ${colors.lightGrayHover};

        > ul {
          height: auto;
          opacity: 1;
          display: block;

          ${breakpoints.tabletPortrait} {
            display: none ;
          }
        }
      }
    }
  }
`

export const cartIconCss = css`
  fill: ${colors.lightGray};
  position: relative;
  margin: ${unitConverter('0')};
  margin-right: 3px;
`
export const apolloIconCss = css`
  height: 20px;
  width: 20x;
  fill: ${colors.white};
  opacity: .6;
`

export const homeIconCss = css`
  height: 20px;
  width: 20x;
  fill: ${colors.lightGray};
  position: relative;
  top: -1px;
  margin-right: 3px;
`

export const barCodeScanIconCss = css`
  height: 20px;
  width: 20x;
  fill: ${colors.lightGray};
  margin-right: 5px;
`
export const eventsIconCss = css`
  height: 20px;
  width: 20x;
  fill: ${colors.lightGray};
  margin-right: 5px;
`

export const menuIconCss = css`
  height: 40px;
  width: 40x;
  fill: ${colors.lightGray};
`

export const homeLinkCss = css`
  display: none;

  ${breakpoints.tabletPortrait} {
    display: block;
  }
`
