/* @jsx jsx */
import React from 'react';
import {css, jsx} from '@emotion/react';
import {colors, breakpoints, unitConverter, fonts} from '@utils/base'
import Heading3 from "@atoms/headings/heading3/heading3"
import Heading2 from "@atoms/headings/heading2/heading2"
import FooterLinkList from "@molecules/footerLinkList"

const footerCss = css`
  text-align: center;
  padding-bottom: ${unitConverter('20px')};
  border-top: ${unitConverter('1px')} solid ${colors.lightGray};
  padding-top: ${unitConverter('20px')};

  ${breakpoints.mobile} {
    padding: ${unitConverter('20px 0 20px')};
  }
`

const heading3Css = css`
  font-size: ${unitConverter('32px')};
  ${breakpoints.tabletPortrait} { font-size: ${unitConverter('24px')}; }
  ${breakpoints.mobile} { font-size: ${unitConverter('18px')}; }
`

const heading2Css = css`
  font-size: ${unitConverter('34px')};
  ${breakpoints.tabletPortrait} { font-size: ${unitConverter('26px')}; }
  ${breakpoints.mobile} { font-size: ${unitConverter('20px')}; }
`

const Footer = ({tagline, listOfLinks, address}) => (
  <footer css={footerCss}>
    {tagline && <Heading3 css={heading3Css}>{tagline}</Heading3>}
    {listOfLinks && <FooterLinkList links={listOfLinks}/>}
    {address && <Heading2 css={heading2Css}>{address}</Heading2>}
  </footer>
)

export default Footer
