/* @jsx jsx */
import React, {useContext} from 'react'
import {jsx} from '@emotion/react'
import {notificationWrapperCss} from './styles'
import NotificationContext from '@context/NotificationContext'

const Notification = () => {
  const {notification, isOpen} = useContext(NotificationContext)

  return (
    <>
      <div css={notificationWrapperCss(notification.isOpen)}>
        {notification.message && (<p>{notification.message}</p>)}
      </div>
    </>
  )
}

export default Notification
