/** @jsx jsx */
import {css, jsx, Global} from '@emotion/react'
import Container from '@atoms/container'
import Footer from '@organisms/footer'
import {unitConverter} from '@utils/base'
import ContextProvider from '@provider/ContextProvider'
import NavMenu from '@components/organisms/NavMenu'

const Layout = ({children, siteTitle, footerSettings, bgImage}) => {
  const layoutCss = css`
    background: transparent url(${bgImage}) 0 0 repeat;
    padding: ${unitConverter('0 20px')};
    min-height: 100vh;
    overflow: hidden;
  `

  return (
    <ContextProvider>
      <NavMenu />
      <div css={layoutCss}>
        <Global styles={css`
          * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }
          body {
            background-color: black;
          }
        `}
        />
        <Container>
          <div>
            {children}
          </div>
          <Footer {...footerSettings} />
        </Container>
      </div>
    </ContextProvider>
  )
}

export default Layout
