/* @jsx jsx */
import React from 'react';
import {css, jsx} from '@emotion/react';
import {Link} from "gatsby";
import Img from 'gatsby-image';
import {getFluidGatsbyImage} from 'gatsby-source-sanity';
import clientConfig from "@root/client-config";
import {unitConverter} from "@utils/base";

const Logo = ({src, linkTo, width, alt, className, ...props}) => {
  const logoCss = css`
    display: inline-block;
    width: 100%;
    max-width: ${unitConverter(`${width}px`)};
    opacity: 85%;
  `;

  return (
    <Link
      className={className}
      css={logoCss}
      to="/"
    >
      <Img
        fluid={getFluidGatsbyImage(
          src.asset._id,
          {maxWidth: width},
          clientConfig.sanity
        )}
        alt={alt}
      />
    </Link>
  );
};

export default Logo;
