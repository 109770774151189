module.exports = {
  sanity: {
    projectId: process.env.GATSBY_SANITY_PROJECT_ID || 'gbe8s2m0',
    dataset: process.env.GATSBY_SANITY_DATASET || 'production',
  },
  youtube: {
    key: 'AIzaSyCUOrKF84CQRd4PESgBgblLlkwu0qDhFo0',
    channelId: 'UCD8d3FGC907iS1qiMF0ccxA',
    playlistId: 'PLoGlSPcr0bRgLcarj634-m-nV4NoQwDRl',
  }
};
