import React from 'react';
import styled from '@emotion/styled';
import {colors, fonts, fontWeights} from "@utils/base";

const Heading2 = ({children, className}) => {
  className = className || '';
  const Heading = styled.h2`
    font-family: ${fonts.tektonPro};
    font-weight: ${fontWeights.normal};
    color: ${colors.headings};
  `;

  return <Heading className={className}>{children}</Heading>;
};

export default Heading2;
