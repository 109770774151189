/* @jsx jsx */
import React from 'react';
import {css, jsx} from "@emotion/react";
import {breakpoints, unitConverter} from "@utils/base";

const Container = ({children}) => {

  const containerCss = css`
    width: 100%;
    max-width: ${unitConverter('1200px')};
    margin: 0 auto;

    ${breakpoints.smallDesktop} {
      padding: 0 0px;
    }
  `;

  return (
    <div css={containerCss}>{children}</div>
  )
};

export default Container;
