/* @jsx jsx */
import React from 'react';
import {css, jsx} from '@emotion/react';
import Logo from '@molecules/logo';
import {breakpoints, unitConverter} from '@utils/base';
import YoutubeSubscribeButton from "@components/molecules/youtubeSubscribe";
import Search from '@molecules/Search/index';
import subscribeImage from '@img/subscribe.png';

const Header = ({logo, tagline, logoWidth = 250, setSearchTerm = () => {}, showSearch = true}) => {
  const headerCss = css`
    transition: all .2s ease-in-out;
    margin: ${unitConverter('40px 0 0')};
    ${breakpoints.mobile} { margin: ${unitConverter('50px auto 20px')}; }
    position: relative;
  `;


  const searchWrapperCSS = css`
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;

    ${breakpoints.tabletPortrait} {
      position: relative;
      margin-bottom: 40px;

      form {
        display: inline-block;
        margin: 0 auto;
      }
    }

    ${breakpoints.mobile} {
      margin-bottom: 0;
    }
  `;

  const logoCss = css`
    width: ${unitConverter(`${logoWidth}px`)};
    margin: ${unitConverter(`0 0 20px`)};
    display: block;
    max-width: 100%;

    ${breakpoints.tabletPortrait} {
      margin: 0 auto 40px;
    }
  `;

  const subscribeWrapperCSS = css`
    position: absolute;
    top: 0;
    right: 0;
    height: ${unitConverter('100px')};
    width: ${unitConverter('542px')};
    background: transparent url(${subscribeImage}) 60% 0 no-repeat;
    background-size: 60%;

    ${breakpoints.tabletLandscape} {
      background-size: 40%;
      background-position: 70% 10px;
    }

    ${breakpoints.tabletPortrait} {
      position: relative;
      background-size: 80%;
      background-position: 0 0;
      width: ${unitConverter('342px')};
      margin: 0 auto 20px;
    }
  `;

  const ytSubWrapperCss = css`
    position: relative;
    width: ${unitConverter('115px')};
    position: absolute;
    top: 0;
    right: 20px;
    height: ${unitConverter('25px')};
    z-index: 2;
  `;

  return (
    <>
      <header css={headerCss}>
        <Logo alt={tagline} src={logo} width={logoWidth} linkTo="/" css={logoCss} className="logo" />
        <div css={subscribeWrapperCSS}>
          <div css={ytSubWrapperCss}>
            <YoutubeSubscribeButton channel="UCD8d3FGC907iS1qiMF0ccxA" />
          </div>
        </div>
        {showSearch && <Search styles={searchWrapperCSS} setTerm={setSearchTerm} />}
      </header>
    </>
  );
};

export default Header;

