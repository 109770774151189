import React from 'react'

const ApolloIcon = ({className}) => (
  <svg
    version='1.0' xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 1730.000000 1306.000000'
    preserveAspectRatio='xMidYMid meet' className={className}
  >

    <g transform='translate(0.000000,1306.000000) scale(0.100000,-0.100000)'>
      <path d='M7925 10823 c-22 -2 -75 -10 -119 -18 -43 -8 -104 -15 -136 -15 -83
0 -1739 -168 -1774 -180 -16 -6 -60 -10 -98 -10 -92 0 -1940 -185 -2043 -205
-44 -8 -96 -15 -115 -15 -41 0 -49 -5 -78 -46 l-21 -31 141 -6 c77 -3 143 -10
147 -15 16 -27 164 -16 1025 75 489 52 895 92 902 89 11 -4 14 -40 14 -171 0
-124 3 -167 13 -169 6 -3 43 -7 81 -11 l69 -6 -6 190 -6 189 42 6 c129 18
1736 186 1757 184 19 -2 54 -46 172 -218 l147 -215 1 -162 0 -163 33 0 c17 0
62 -3 100 -7 l67 -6 0 171 0 172 180 217 c180 217 181 218 218 216 20 -2 494
-32 1052 -68 558 -36 1035 -68 1059 -71 l44 -6 -6 -319 c-6 -299 -5 -320 11
-315 9 2 35 7 57 10 22 4 52 9 66 12 l26 5 5 294 c2 162 7 297 10 300 3 3 589
-33 1302 -80 1055 -69 1308 -83 1359 -75 34 5 66 10 70 10 4 0 -16 29 -45 65
-50 64 -53 65 -99 65 -26 0 -81 5 -121 10 -98 13 -2478 161 -2489 154 -5 -3
-44 0 -86 5 -86 12 -1940 129 -2148 136 -71 2 -170 9 -220 14 -97 11 -495 21
-560 14z m105 -220 l0 -98 -55 82 c-30 46 -55 87 -55 92 0 6 24 12 53 14 28 2
53 4 55 5 1 1 2 -42 2 -95z m369 71 c4 -4 -148 -194 -155 -194 -2 0 -4 48 -4
106 l0 106 78 -7 c42 -4 79 -9 81 -11z'
      />
      <path d='M13640 10483 c0 -5 22 -32 48 -62 l48 -53 75 12 c50 9 68 15 54 20
-24 8 50 20 139 21 34 0 93 6 131 12 l70 13 -115 12 c-238 26 -450 38 -450 25z'
      />
      <path d='M12145 10082 l-2200 -365 -3470 307 c-1908 168 -3476 306 -3482 306
-9 0 -13 -23 -13 -80 l0 -80 29 -6 c28 -6 30 -9 35 -68 3 -33 40 -779 81
-1656 41 -877 76 -1619 78 -1647 4 -53 4 -53 -26 -53 l-30 0 7 -71 c7 -82 10
-89 36 -89 12 0 21 -9 24 -22 4 -23 177 -3657 176 -3710 0 -25 -2 -27 -25 -18
-24 9 -25 8 -25 -34 0 -24 3 -58 6 -75 l7 -31 3236 -1077 c1780 -592 3241
-1076 3247 -1076 10 0 4170 2566 4186 2581 3 4 8 38 10 76 l3 70 -32 8 c-27 7
-33 13 -33 37 0 43 158 3333 166 3438 l6 92 33 11 c38 12 45 30 45 113 0 56 0
57 -29 57 -17 0 -33 4 -36 10 -3 5 29 735 71 1622 83 1733 76 1638 129 1638
20 0 23 7 29 61 9 95 9 99 -16 98 -13 -1 -1013 -166 -2223 -367z m1820 52 c64
-141 42 -155 -53 -37 -63 78 -69 89 -55 101 9 6 30 12 45 12 26 0 32 -7 63
-76z m-149 -161 c88 -108 162 -202 165 -208 8 -12 -114 -2701 -122 -2709 -3
-4 -85 6 -183 20 -97 15 -179 24 -182 21 -6 -5 88 -132 114 -154 14 -11 137
-30 157 -24 6 2 28 3 50 2 25 -1 80 14 145 39 97 37 167 50 183 33 4 -3 -388
-157 -871 -340 -796 -303 -881 -333 -919 -328 l-43 6 0 95 c0 134 21 688 26
693 4 4 981 -136 1014 -145 8 -2 44 -43 79 -90 35 -46 68 -87 72 -90 10 -6
131 39 131 50 0 4 -40 59 -88 121 -63 82 -95 116 -113 119 -14 3 -59 10 -100
17 -47 7 -69 14 -60 20 11 6 11 9 -1 13 -8 2 -208 32 -445 65 -236 34 -442 64
-456 67 -24 5 -26 9 -28 65 -2 63 79 2612 84 2617 1 1 278 51 616 111 338 60
615 110 615 110 1 1 73 -88 160 -196z m-10456 147 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m1427 -124 c540 -50 984 -94
987 -96 5 -6 69 -2541 63 -2546 -2 -2 -76 -20 -165 -41 -154 -36 -184 -47
-144 -55 22 -5 -107 -38 -147 -38 -16 0 -31 4 -33 8 -1 5 -266 -54 -588 -130
-916 -215 -898 -211 -870 -218 32 -8 21 -13 -68 -35 -73 -17 -74 -18 -109 -74
-70 -111 -67 -107 -71 -89 -2 9 10 50 26 92 17 42 29 76 28 76 -72 -21 -193
-46 -197 -41 -8 8 -133 2767 -126 2784 5 14 313 465 334 488 10 12 25 15 56
12 23 -2 484 -46 1024 -97z m7463 -118 c0 -81 -70 -2383 -76 -2484 l-6 -101
-26 4 c-57 9 -73 14 -68 22 3 4 -41 14 -97 21 -56 7 -109 14 -117 15 -8 2 -37
6 -65 9 -27 4 -61 9 -75 11 -66 11 -129 18 -167 18 -23 1 -40 4 -38 7 2 3 -15
4 -37 2 -22 -3 -38 0 -35 5 3 4 -13 7 -35 5 -24 -3 -38 0 -35 5 3 5 -6 8 -21
6 -31 -4 -439 51 -448 60 -6 6 34 2198 40 2204 2 2 1269 228 1299 232 4 1 7
-18 7 -41z m-6177 -3 l67 -7 0 -41 c0 -23 11 -544 25 -1157 14 -613 25 -1142
25 -1176 l0 -61 -96 -22 c-53 -12 -97 -21 -99 -19 -2 3 -56 2355 -55 2441 l0
58 33 -5 c17 -3 62 -8 100 -11z m1116 -104 c464 -44 844 -81 846 -83 1 -2 5
-411 9 -910 l6 -907 -122 -28 c-68 -15 -115 -29 -105 -31 31 -6 18 -18 -34
-30 -59 -14 -96 -15 -101 -4 -3 9 -1232 -278 -1244 -290 -4 -5 1 -8 12 -9 10
-1 1 -7 -21 -14 -22 -7 -43 -11 -46 -9 -4 2 -7 77 -8 166 -1 90 -11 595 -21
1123 -20 989 -22 1105 -17 1105 2 0 383 -36 846 -79z m1499 -141 c235 -22 430
-42 434 -43 4 -1 124 -159 268 -352 l261 -350 -4 -597 c-2 -329 -7 -601 -10
-605 -4 -3 -119 9 -255 29 -136 20 -445 64 -688 99 l-441 63 -6 480 c-11 745
-9 1316 4 1316 6 0 203 -18 437 -40z m2084 -146 c-12 -771 -35 -1980 -37
-1982 -1 -1 -78 9 -170 23 -93 14 -175 24 -183 23 -9 -2 3 -26 39 -73 29 -38
53 -71 54 -73 1 -1 57 -10 124 -21 67 -10 124 -21 128 -24 8 -9 4 -278 -5
-312 -7 -24 -24 -4 -198 232 l-191 258 -82 12 c-81 11 -117 23 -71 23 49 0 10
9 -109 26 l-123 18 7 620 7 621 185 355 c102 195 189 359 195 365 9 10 404 85
423 80 6 -1 9 -64 7 -171z m-1164 59 l52 -6 0 -112 c0 -62 -3 -139 -6 -173
l-7 -61 -119 157 c-66 86 -129 170 -141 186 l-21 29 95 -7 c52 -4 118 -9 147
-13z m486 -160 c-138 -264 -124 -259 -124 -46 l0 181 73 12 c39 6 77 13 82 15
6 2 20 4 33 4 21 1 17 -11 -64 -166z m-1516 -1590 c-10 -2 -26 -2 -35 0 -10 3
-2 5 17 5 19 0 27 -2 18 -5z m70 -10 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19
0 27 -2 18 -5z m-598 -138 c0 -52 -2 -95 -5 -95 -2 0 -32 36 -65 80 -41 54
-57 82 -48 85 17 6 90 23 106 24 9 1 12 -24 12 -94z m295 70 c91 -12 90 -12
49 -58 -21 -23 -61 -68 -91 -100 l-53 -58 0 117 c0 115 0 116 23 111 12 -3 45
-9 72 -12z m-442 -231 l144 -187 6 -510 c4 -281 7 -622 7 -758 0 -228 -1 -248
-17 -251 -14 -3 182 -48 207 -48 3 0 4 348 2 774 l-2 773 180 197 c132 142
187 196 203 196 12 0 243 -33 512 -73 l490 -73 3 -124 c1 -69 -1 -531 -6
-1028 -7 -718 -11 -905 -21 -911 -8 -5 12 -13 53 -21 40 -7 66 -17 66 -25 0
-9 -104 9 -357 61 -197 40 -1601 326 -3120 636 -1519 310 -2759 564 -2754 566
5 2 153 -24 329 -58 309 -58 442 -78 257 -39 -49 11 -93 25 -97 33 -6 11 -96
31 -179 39 l-26 2 22 35 22 35 799 193 c440 106 804 192 810 192 6 0 104 -103
217 -228 l205 -228 3 -220 4 -220 84 -18 c46 -9 86 -15 89 -11 3 3 1 5 -5 5
-6 0 -12 89 -17 238 l-7 239 93 162 93 163 6 -114 c4 -62 8 -253 10 -425 l4
-311 108 -22 c60 -12 111 -19 115 -16 3 3 -2 6 -10 6 -14 0 -17 45 -23 368 -4
202 -8 479 -9 617 l-1 249 665 162 c366 89 673 162 682 163 11 1 69 -67 161
-185z m2202 -29 c72 -10 138 -23 147 -29 10 -6 119 -148 244 -316 l225 -305
-6 -537 -7 -537 73 28 c74 28 74 28 81 72 5 24 11 250 14 501 l6 457 247 239
246 239 389 -58 c214 -32 392 -61 396 -66 4 -4 2 -190 -6 -413 -7 -223 -13
-417 -14 -432 l0 -27 36 15 c37 15 126 11 107 -6 -4 -4 -515 -200 -1136 -435
-1111 -422 -1128 -428 -1175 -419 l-47 9 0 75 c0 327 17 1759 21 1853 5 81 10
117 18 114 6 -3 70 -12 141 -22z m1342 -75 c42 -12 8 -10 -82 5 l-80 14 67 -5
c37 -3 80 -9 95 -14z m-289 -82 c7 -2 -51 -66 -128 -143 l-140 -140 0 156 c0
86 2 158 5 161 4 4 198 -21 263 -34z m-4978 -17 c0 -6 -24 -52 -54 -103 -36
-63 -63 -125 -82 -192 -35 -127 -42 -111 -43 101 l-1 163 28 6 c15 3 50 12 77
19 63 16 75 17 75 6z m-335 -93 c3 -13 4 -79 3 -148 l-3 -124 -100 112 c-55
63 -100 119 -100 125 0 9 132 48 187 56 4 1 10 -9 13 -21z m7958 -535 l17 -38
-25 28 c-25 28 -32 47 -17 47 5 0 16 -17 25 -37z m-153 -56 c15 -17 61 -78
104 -134 l78 -102 -67 -1493 c-37 -821 -70 -1500 -73 -1508 -3 -8 -4 -21 -3
-27 1 -7 -2 -13 -6 -13 -16 -1 -712 164 -709 168 2 2 89 -15 192 -38 104 -22
197 -40 208 -38 11 2 -240 64 -557 138 -318 74 -582 137 -588 140 -6 4 1 323
20 916 l30 909 162 355 c89 195 165 359 170 365 10 10 986 394 1004 395 6 0
21 -15 35 -33z m-8905 -341 c484 -101 905 -189 936 -195 l57 -12 70 -102 70
-102 25 -1025 c14 -563 25 -1026 23 -1028 -8 -7 -2249 -862 -2253 -859 -2 2
-35 700 -73 1550 l-71 1547 161 205 c89 113 164 205 168 205 4 0 403 -83 887
-184z m7649 -279 c-48 -105 -91 -196 -96 -201 -9 -11 -9 200 1 287 l6 59 85
33 c47 18 87 29 88 24 2 -5 -36 -96 -84 -202z m-264 -32 c0 -58 -3 -105 -7
-105 -11 1 -95 138 -97 157 -1 13 68 50 97 52 4 1 7 -46 7 -104z m-6114 34 c4
-7 9 -7 13 -2 4 6 42 2 97 -10 l91 -19 7 -51 c9 -76 7 -225 -2 -231 -5 -3 -59
69 -121 159 -61 91 -107 165 -102 165 5 0 13 -5 17 -11z m5959 -274 l141 -220
-27 -873 c-15 -480 -29 -876 -32 -879 -4 -3 -46 4 -94 16 -48 11 -99 23 -113
26 -14 2 -109 25 -212 49 -103 25 -194 43 -202 40 -11 -4 -519 101 -589 122
-42 13 -6 12 48 -1 33 -8 67 -14 75 -14 28 0 -11 15 -73 28 -32 7 -61 19 -63
26 -6 14 24 1527 30 1533 11 10 943 370 956 369 8 -1 78 -101 155 -222z
m-5416 172 l104 -22 -114 -127 c-63 -69 -117 -127 -121 -127 -5 -1 -8 67 -8
149 0 174 -15 160 139 127z m1076 -225 l420 -88 5 -470 c4 -317 2 -473 -5
-481 -10 -11 -1573 -613 -1592 -613 -6 0 -12 146 -17 373 -3 204 -9 498 -12
652 l-5 280 231 251 231 251 162 -34 c89 -18 351 -73 582 -121z m-1492 -204
l96 -143 10 -570 c6 -313 13 -624 17 -691 l5 -121 -93 -35 c-57 -22 -95 -32
-99 -26 -4 5 -7 78 -8 161 -1 84 -8 420 -16 747 -15 610 -18 820 -11 820 2 0
47 -64 99 -142z m2557 -19 c217 -45 401 -87 407 -92 14 -11 480 -666 502 -705
10 -20 12 -57 6 -179 -4 -84 -8 -153 -9 -153 0 0 -302 71 -671 158 l-670 157
-3 454 -2 453 22 -5 c13 -3 201 -43 418 -88z m1997 -221 c-4 -156 -10 -478
-14 -716 -3 -238 -9 -434 -12 -436 -5 -3 -557 124 -738 169 -23 5 -23 7 -23
185 l0 180 176 362 c97 200 180 367 184 371 9 10 400 165 419 166 12 1 13 -42
8 -281z m-1164 47 l87 -18 0 -118 c0 -65 -4 -120 -8 -123 -7 -4 -175 217 -205
271 -8 14 6 12 126 -12z m576 -2 c0 -5 -42 -91 -94 -193 l-94 -185 2 160 2
159 80 32 c83 34 105 39 104 27z m-1968 -783 c54 -13 88 -16 99 -10 12 6 217
-39 691 -152 371 -88 676 -162 677 -163 6 -6 -20 -3083 -26 -3195 l-7 -106
-1445 473 c-795 260 -1449 471 -1454 470 -4 -2 665 -223 1489 -492 824 -270
1505 -495 1514 -502 80 -58 -136 10 -3017 955 -1656 544 -3015 992 -3019 996
-4 4 -1 5 7 2 72 -25 984 -296 986 -293 2 2 -212 74 -476 160 -264 87 -480
161 -480 165 0 4 503 203 1118 442 919 357 1124 434 1158 432 54 -3 124 24
124 48 0 13 24 27 92 54 51 19 93 34 95 33 2 -3 43 -1753 43 -1849 l0 -27 -72
25 c-39 13 -76 24 -82 23 -6 0 101 -38 239 -85 137 -46 254 -84 260 -83 5 0
-26 13 -70 28 l-80 28 -7 64 c-11 109 -42 1953 -32 1956 14 6 1589 621 1591
622 1 1 39 -8 84 -19z m2524 -431 c6 0 5 -3 -2 -5 -13 -6 -364 74 -376 86 -4
4 77 -13 180 -36 103 -24 193 -44 198 -45z m-368 -56 c292 -69 373 -91 373
-103 0 -20 111 -45 137 -32 13 7 200 -34 633 -137 l615 -147 -3 -105 c-12
-455 -33 -1136 -38 -1238 l-6 -124 36 23 c29 17 44 20 76 15 22 -4 40 -10 40
-13 -1 -4 -489 -304 -1086 -667 -894 -544 -1091 -660 -1117 -658 -18 1 -45 4
-61 5 l-28 3 6 355 c3 195 11 821 16 1390 11 1114 19 1520 29 1520 3 0 174
-39 378 -87z m2660 -634 c395 -94 721 -173 726 -176 4 -2 7 -11 7 -19 0 -26
37 -15 123 37 75 44 89 49 122 43 20 -3 33 -10 28 -15 -5 -5 -392 -242 -861
-528 l-853 -519 -37 11 c-20 6 -38 11 -39 12 -1 0 7 262 17 580 11 319 20 617
20 663 0 65 3 82 15 82 8 0 337 -77 732 -171z'
      />
      <path d='M13695 6870 c3 -5 12 -7 20 -3 21 7 19 13 -6 13 -11 0 -18 -4 -14
-10z' />
      <path d='M4188 6553 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M4248 6543 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M4298 6533 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M4348 6523 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M4398 6513 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M4448 6503 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M4498 6493 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M4548 6483 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M4588 6473 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M4638 6463 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M4688 6453 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M4738 6443 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M4788 6433 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M4838 6423 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M4888 6413 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M4938 6403 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M4988 6393 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M5038 6383 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M5088 6373 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M5138 6363 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M5178 6353 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M5228 6343 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M5278 6333 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M5328 6323 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M5378 6313 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M5428 6303 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M5478 6293 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M5528 6283 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M5578 6273 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M5616 6271 c8 -9 204 -52 228 -50 23 1 -42 22 -124 40 -98 21 -118
23 -104 10z'
      />
      <path d='M6068 6173 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M6118 6163 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M6168 6153 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M6458 6093 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M6508 6083 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M6558 6073 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M6608 6063 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M6658 6053 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M6708 6043 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M6758 6033 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M6825 6031 c-24 -5 -2 -12 105 -34 159 -32 156 -32 135 -11 -9 10
-56 25 -103 35 -95 20 -93 20 -137 10z'
      />
      <path d='M7098 5963 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M7148 5953 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M7198 5943 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M7248 5933 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M7298 5923 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M7348 5913 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M7398 5903 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M7448 5893 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M7498 5883 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M7538 5873 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M7588 5863 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M7638 5853 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M7688 5843 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M7738 5833 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M7788 5823 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M7838 5813 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M7888 5803 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M7938 5793 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M7988 5783 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M8278 5723 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M8328 5713 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M8378 5703 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M8428 5693 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M8478 5683 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M8528 5673 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M8578 5663 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M8628 5653 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M8678 5643 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M8718 5633 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M8768 5623 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M8818 5613 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M8868 5603 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M8911 5596 c6 -7 321 -67 326 -63 7 8 -54 26 -156 46 -94 19 -181 27
-170 17z'
      />
      <path d='M9268 5523 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M9308 5513 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M9358 5503 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M9408 5493 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M9458 5483 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M9508 5473 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M9558 5463 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
      <path d='M11718 6123 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z' />
      <path d='M4790 2399 c79 -27 149 -49 155 -48 5 0 -55 23 -135 50 -79 27 -149
49 -155 48 -5 0 55 -23 135 -50z'
      />
      <path d='M4986 2335 c17 -7 44 -16 60 -20 25 -4 24 -3 -6 8 -19 8 -46 17 -60
20 -19 5 -17 3 6 -8z'
      />
      <path d='M5355 2214 c121 -41 224 -74 230 -73 5 0 -89 34 -210 75 -121 41
-224 74 -230 73 -5 0 89 -34 210 -75z'
      />
      <path d='M5890 2039 c129 -43 239 -79 245 -78 5 0 -96 36 -225 80 -129 43
-239 79 -245 78 -5 0 96 -36 225 -80z'
      />
      <path d='M3355 10361 c-82 -9 -136 -17 -120 -19 17 -1 50 -7 75 -13 25 -6 77
-14 117 -18 l72 -7 15 38 c10 27 11 38 3 37 -7 -1 -79 -9 -162 -18z'
      />
      <path d='M12268 10133 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z' />
    </g>
  </svg>
)

export default ApolloIcon
