import React from 'react'

const EventsIcon = ({className}) => (
  <svg className={className} viewBox="0 0 32 32" id="icon" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <style>.cls-1{'{fill: none;}'}</style>
    </defs>
    <path d="M28,6a2,2,0,0,0-2-2H22V2H20V4H12V2H10V4H6A2,2,0,0,0,4,6V26a2,2,0,0,0,2,2h4V26H6V6h4V8h2V6h8V8h2V6h4v6h2Z"/>
    <polygon
      points="21 15 23.549 19.938 29 20.729 25 24.573 26 30 21 27.438 16 30 17 24.573 13 20.729 18.6 19.938 21 15"/>
    <rect className="cls-1" width="32" height="32"/>
  </svg>
)

export default EventsIcon
