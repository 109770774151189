export const unitConverter = (value, base) => {
  base = base || 16
  const parts = value.split(' ')

  parts.forEach((part, index) => {
    const matches = part.match(/(\d+)(\w+)/i)
    if (matches) {
      const [full, value, type] = matches // eslint-disable-line no-unused-vars
      switch (type) {
        case 'px':
          parts[index] = `${parseFloat(value / base)}rem`
          break
        case 'rem':
          parts[index] = `${parseFloat(value * base)}px`
          break
        default:
          parts[index] = part
          break
      }
    }
  })

  return parts.join(' ')
}

export const colors = {
  black: '#000',
  white: '#efefef',
  background: '#000',
  text: '#fff',
  secondaryText: '#999',
  headings: '#fff',
  link: '#fff',
  linkHover: '#dfdfdf',
  lightGray: '#666666',
  lightGrayHover: '#dfdfdf',
  darkGray: '#222222',
  darkGrayHover: '#3a3a3a',
  purple: '#8E24AA',
  green: '#66CC00'
}

export const fonts = {
  tektonPro: 'tekton-pro, sans-serif',
  tektonProCondensed: 'tekton-pro-condensed, sans-serif',
  tektonProExtended: 'tekton-pro-extended, sans-serif',
  signo: 'signo, sans-serif'
}

export const fontWeights = {
  bold: 500,
  normal: 400,
  light: 100
}

export const breakpointSizes = {
  smallDesktop: unitConverter('1200px'),
  tabletLandscape: unitConverter('960px'),
  tabletPortrait: unitConverter('768px'),
  mobile: unitConverter('480px'),
  starBarPatternDemo: unitConverter('600px')
}

export const breakpoints = {
  smallDesktop: `@media all and (max-width: ${breakpointSizes.smallDesktop})`,
  tabletLandscape: `@media all and (max-width: ${breakpointSizes.tabletLandscape})`,
  tabletPortrait: `@media all and (max-width: ${breakpointSizes.tabletPortrait})`,
  mobile: `@media all and (max-width: ${breakpointSizes.mobile})`,
  starBarPatternDemo: `@media all and (max-width: ${breakpointSizes.starBarPatternDemo})`
}

export const transitions = {
  fast: '.2s',
  normal: '1s',
  slow: '10s'
}
