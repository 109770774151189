/* @jsx jsx */
import React from 'react';
import {css, jsx} from '@emotion/react';
import ReferenceLink from "@atoms/referenceLink";
import Link from "@atoms/link";
import {colors, fonts, unitConverter} from "@utils/base";

const listCss = css`
  list-style: none outside;
  padding: ${unitConverter('10px 0')};
  font-family: ${fonts.tektonPro};
  font-size: ${unitConverter('14px')};

  li {
    color: ${colors.text};
    display: inline-block;

    a {
      text-decoration: none;
      color: ${colors.link};

      &:hover {
        color: ${colors.linkHover};
        border-bottom: 1px dotted ${colors.link}
      }
    }

    &::after {
      content: ' | ';
      color: ${colors.text};
      margin-right: ${unitConverter('5px')};
    }

    &:last-of-type {
      &::after {
        content: '';
      }
    }
  }
`;

const displayLinks = (links) => links.map((item, index) => {
    switch (item.__typename) {
      case 'SanityIndustrySegments' :
        return (<li key={index}><ReferenceLink {...item}/></li>);
      case 'SanityLink' :
        return (<li key={index}><Link {...item}/></li>);
    }
  }
);

const FooterLinkList = ({links}) => {
  return (
    <ul css={listCss}>
      {displayLinks(links)}
    </ul>
  )
};
export default FooterLinkList;
