import React, {useState, useEffect, useCallback} from 'react'
import Client from 'shopify-buy'

import StoreContext from '@context/StoreContext'
import NotificationContext from '@context/NotificationContext'
import Notification from '@molecules/Notification'

const get = require('lodash.get')

const client = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
  domain: `${process.env.GATSBY_SHOP_NAME}.myshopify.com`
})

const ContextProvider = ({children}) => {
  /**
   * Store Context Provider
   */
  const [store, updateStore] = useState({
    client,
    adding: false,
    checkout: {lineItems: []},
    products: [],
    shop: {}
  })
  let isRemoved = false

  useEffect(() => {
    const initializeCheckout = async () => {
      // Check for an existing cart.
      const isBrowser = typeof window !== 'undefined'
      const localStorage = isBrowser ? window.localStorage : {}
      const existingCheckoutID = isBrowser
        ? localStorage.getItem('shopify_checkout_id')
        : null

      const setCheckoutInState = checkout => {
        if (isBrowser) {
          localStorage.setItem('shopify_checkout_id', checkout.id)
        }

        updateStore(prevState => {
          return {...prevState, checkout}
        })
      }

      const createNewCheckout = () => store.client.checkout.create()
      const fetchCheckout = id => store.client.checkout.fetch(id)

      if (existingCheckoutID) {
        try {
          const checkout = await fetchCheckout(existingCheckoutID)
          // Make sure this cart hasn’t already been purchased.
          if (!isRemoved && !checkout.completedAt) {
            setCheckoutInState(checkout)
            return
          }
        } catch (e) {
          localStorage.setItem('shopify_checkout_id', null)
        }
      }

      const newCheckout = await createNewCheckout()
      if (!isRemoved) {
        setCheckoutInState(newCheckout)
      }
    }

    initializeCheckout()
  }, [store.client.checkout])

  useEffect(() => () => { isRemoved = true }, [])

  const storeContext = {
    store,
    addVariantToCart: (variantId, quantity, productTitle) => {
      if (variantId === '' || !quantity) {
        console.error('Both a size and quantity are required.')
        return
      }

      updateStore(prevState => {
        return {...prevState, adding: true}
      })

      const {checkout, client} = store

      const checkoutId = checkout.id
      const lineItemsToUpdate = [
        {variantId, quantity: parseInt(quantity, 10)}
      ]

      return client.checkout
        .addLineItems(checkoutId, lineItemsToUpdate)
        .then(checkout => {
          updateStore(prevState => {
            addNotification(`${productTitle} added to cart`)
            return {...prevState, checkout, adding: false}
          })
        })
    },
    removeLineItem: (client, checkoutID, lineItemID, productTitle) => {
      return client.checkout
        .removeLineItems(checkoutID, [lineItemID])
        .then(res => {
          updateStore(prevState => {
            addNotification(`${productTitle} removed from cart`)
            return {...prevState, checkout: res}
          })
        })
    },
    updateLineItem: (client, checkoutID, lineItemID, quantity) => {
      const lineItemsToUpdate = [
        {id: lineItemID, quantity: parseInt(quantity, 10)}
      ]

      return client.checkout
        .updateLineItems(checkoutID, lineItemsToUpdate)
        .then(res => {
          updateStore(prevState => {
            return {...prevState, checkout: res}
          })
        })
    },
    addCouponCode: (client, checkoutID, code) => {
      return client.checkout.addDiscount(checkoutID, code).then(res => {
        updateStore(prevState => {
          if (res.discountApplications.length > 0) {
            if (code.toLowerCase() === get(res, 'discountApplications[0].code', '').toLowerCase()) {
              addNotification(`Discount code "${code.toUpperCase()}" applied successfully`)
            } else {
              addNotification('Invalid coupon code')
            }
          } else {
            addNotification('Invalid coupon code')
          }
          return {...prevState, checkout: res}
        })
      })
    },
    removeCouponCodes: (client, checkoutID) => {
      return client.checkout.removeDiscount(checkoutID).then(res => {
        updateStore(prevState => {
          const code = get(prevState, 'checkout.discountApplications[0].code', '').toUpperCase()
          addNotification(`Discount code "${code}" removed`)
          return {...prevState, checkout: res}
        })
      })
    }
  }

  /**
   * Notification Context Provider
   */
  const [notification, setNotification] = useState({
    isOpen: false,
    message: null
  })

  const addNotification = message => {
    setNotification({isOpen: true, message})
    setTimeout(() => {
      removeNotification()
    }, 2000)
  }

  const removeNotification = () => setTimeout(() => {
    setNotification(notification => { return {...notification, isOpen: false} })
    setTimeout(() => {
      setNotification(notification => { return {...notification, message: null} })
    }, 500)
  }, 1100)

  const notificationContext = {
    notification,
    addNotification: useCallback(msg => addNotification(msg), []),
    removeNotification: useCallback(() => removeNotification(), [])
  }

  return (
    <StoreContext.Provider value={storeContext}>
      <NotificationContext.Provider value={notificationContext}>
        <Notification />
        {children}
      </NotificationContext.Provider>
    </StoreContext.Provider>
  )
}
export default ContextProvider
