/* @jsx jsx */
import React, { useRef } from 'react';
import {jsx, css} from '@emotion/react';
import {colors, breakpoints, unitConverter, fonts, fontWeights} from '@utils/base';
import searchBg from '@img/search-bg.jpg';

const formCSS = css`
  ${breakpoints.mobile} {
    margin: 0;
    width: 100%;
    position: relative;
  }
`;

const inputCSS = css`
  padding: ${unitConverter('10px')};
  font-size: ${unitConverter('14px')};
  outline: none;
  width: ${unitConverter('250px')};
  border: ${unitConverter('1px')} solid ${colors.lightGray};
  background-color: ${colors.black};
  color: ${colors.lightGray};
  text-shadow: ${colors.black} -1px -1px 3px;
  font-family: ${fonts.signo};
  font-weight: ${fontWeights.bold};
  background: #0c0c0c url(${searchBg}) 0 0 no-repeat;

  &::placeholder {
    color: ${colors.lightGray};
  }

  ${breakpoints.mobile} {
    width: 90%;
  }
`;

const buttonCSS = css`
  font-family: ${fonts.signo};
  font-weight: ${fontWeights.bold};
  background-color: ${colors.darkGray};
  border: ${unitConverter('1px')} solid ${colors.lightGray};
  border-left: none;
  padding: ${unitConverter('10px')};
  font-size: ${unitConverter('14px')};
  outline: none;
  cursor: pointer;
  color: ${colors.lightGray};

  &::hover {
    color: ${colors.lightGrayHover};
  }

  ${breakpoints.mobile} {
    width: 10%;
  }
`;

const Search = ({setTerm = () => {}, styles}) => {
  const searchRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    setTerm(searchRef.current.value ? searchRef.current.value : ":::RESET:::")
  }

  return (
    <div css={styles}>
      <form action='' method='post' onSubmit={handleSubmit} css={formCSS}>
        <input type='text' name='search' placeholder='Search Videos' ref={searchRef} css={inputCSS} />
        <input type='submit' value='GO' css={buttonCSS} />
      </form>
    </div>
  )
};

export default Search;
