import React from 'react'

const BarCodeScanIcon = ({className}) => (
  <svg
    version='1.1'
    viewBox='0 0 24 24'
    className={className}
  >
    <path
      d='M4,6H6V18H4V6M7,6H8V18H7V6M9,6H12V18H9V6M13,6H14V18H13V6M16,6H18V18H16V6M19,6H20V18H19V6M2,4V8H0V4A2,2 0 0,1 2,2H6V4H2M22,2A2,2 0 0,1 24,4V8H22V4H18V2H22M2,16V20H6V22H2A2,2 0 0,1 0,20V16H2M22,20V16H24V20A2,2 0 0,1 22,22H18V20H22Z'
    />
  </svg>
)

export default BarCodeScanIcon
