/* @jsx jsx */
import React, {useContext, useState, useEffect} from 'react'
import {useStaticQuery, graphql, Link} from 'gatsby'
import reduce from 'lodash/reduce'
import {jsx} from '@emotion/react'
import StoreContext from "@context/StoreContext"
import Container from '@atoms/container'
import ShoppingCartIcon from '@icons/ShoppingCartIcon'
import ApolloIcon from '@icons/ApolloIcon'
import BarCodeScanIcon from '@icons/BarCodeScanIcon'
import debounce from 'lodash/debounce'

import {
  menuCss,
  cartIconCss,
  apolloIconCss,
  barCodeScanIconCss,
  menuIconCss, homeIconCss, homeLinkCss, eventsIconCss
} from './styles'
import MenuIcon from '@icons/MenuIcon'
import HomeIcon from '@icons/HomeIcon'
import EventsIcon from '@icons/EventsIcon'

const useQuantity = () => {
  const { store: {checkout} } = useContext(StoreContext)
  const items = checkout ? checkout.lineItems : []
  const total = reduce(items, (acc, item) => acc + item.quantity, 0)
  return [total !== 0, total]
}

const NavMenu = () => {
  const [hasItems, quantity] = useQuantity();
  const [collapsed, setCollapsed] = useState(true);
  const data = useStaticQuery(query)
  const products = data.products.edges.map(p => p.node) || []

  const toggleCollapsed = () => setCollapsed(!collapsed)

  const handleResize = debounce(() => {
    if (typeof window !== 'undefined' && window.innerWidth >= 768) { // breakpointSizes.tabletPortrait
      setCollapsed(false)
    } else if (typeof window !== 'undefined' && window.innerWidth < 768) {
      setCollapsed(true)
    }
  }, 200, false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCollapsed(window.innerWidth < 768)
      window.addEventListener('resize', () => handleResize())
    } else {
      setCollapsed(true)
    }
  }, [])

  return (
    <nav css={menuCss}>
      <Container>
        <ul className="mobile-wrap">
          <li>
            <button className="hamburger" onClick={toggleCollapsed}>
              <MenuIcon css={menuIconCss} />
            </button>
            <ul className={`nav ${collapsed ? 'closed' : 'open'}`}>
              <li css={homeLinkCss}>
                <h3>
                  <Link to={'/'}>
                    <HomeIcon css={homeIconCss} />
                    Home
                  </Link>
                </h3>
              </li>

              <li>
                <h3>
                  <Link to={'/events'}>
                    <EventsIcon css={eventsIconCss} />
                    Events
                  </Link>
                </h3>
                <ul>
                  <li key={`event-effpv-bbq-2020`}>
                    <Link to={`/events/effpv-bbq-2020`}>EFFPVBBQ 2020</Link>
                  </li>
                  <li key={`event-effpv-bbq-2021`}>
                    <Link to={`/events/effpv-bbq-2021`}>EFFPVBBQ 2021</Link>
                  </li>
                </ul>
              </li>

              <li>
                <h3>
                  <Link to={'/apollo'}>
                    <ApolloIcon css={apolloIconCss} />
                    Apollo XI Model Aircraft Field
                  </Link>
                </h3>
              </li>
              <li>
                <h3>
                  <Link to={'/products'}>
                    <BarCodeScanIcon css={barCodeScanIconCss} />
                    Products
                  </Link>
                </h3>
                <ul>
                  {products.map(product => <li key={`product-${product.handle}`}><Link to={`/product/${product.handle}`}>{product.title}</Link></li>)}
                </ul>
              </li>
              <li>
                <h3>
                  <Link to={'/cart'}>
                    <ShoppingCartIcon css={cartIconCss}/> Cart
                    <span className={`quantity ${!hasItems ? 'empty' : ''}`}>{quantity}</span>
                  </Link>
                </h3>
              </li>
            </ul>
          </li>
        </ul>
      </Container>
    </nav>
  )
};

export default NavMenu

const query = graphql`
  query NavMenuProductsQuery {
    products: allShopifyProduct {
      edges {
        node {
          title
          handle
        }
      }
    }
  }
`
