import React from 'react'
import { Helmet } from 'react-helmet'

const YoutubeSubscribeButton = ({channel, layout = 'default'}) => (
  <>
    <Helmet>
      <script src="//apis.google.com/js/platform.js"></script>
    </Helmet>
    <span
      className="g-ytsubscribe"
      data-channelid={channel}
      data-layout={layout}
      data-count="default"
    />
  </>
);

export default YoutubeSubscribeButton;
