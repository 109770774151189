import {css} from '@emotion/react'
import {breakpoints, colors, fonts, unitConverter} from '@utils/base'
export const notificationWrapperCss = isOpen => css`
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 40px;
  background-color: ${colors.darkGray};
  opacity: ${isOpen ? 1 : 0};
  visibility: ${isOpen ? 'visible' : 'hidden'};
  transition: all .25s ease-in-out;
  z-index: 20;
  display: block;
  transform: translateX(-50%) translateY(-50%);
  border: ${unitConverter('1px')} solid ${colors.lightGray};
  transform: translateY(-50%) translateX(-50%);

  p {
    font-family: ${fonts.signo};
    color: ${colors.secondaryText};
    font-size: 1em;

    ${breakpoints.tabletPortrait} {
      text-align: center;
      line-height: 2;
    }
  }
`
